import request from '@/utils/ucenterrequest.js'
import qs from 'qs'
// 获取我的信息
export function chat(params) {
  return request({
    url: 'api/ai/chat',
    params: params,
    method: 'get'
  })
}
// 获取流式信息
export function chat2(params) {
  return request({
    url: 'api/aiv2/chat',
    params: params,
    method: 'get'
  })
}
// 规范详情接口
export function getGuifanInfo(params) {
  return request({
    url: 'api/aiv2/getGuifanInfoV2',
    params: params,
    method: 'get'
  })
}
// 点击分享接口
export function createShare(params) {
  return request({
    url: 'api/aiv2/createShare',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 获取分享接口
export function getShare(params) {
  return request({
    url: 'api/aiv2/getShare',
    params: params,
    method: 'get'
  })
}
// 获取历史记录接口
export function gethistorymsg(params) {
  return request({
    url: 'api/aiv2/gethistorymsg',
    params: params,
    method: 'get'
  })
}
// 规范查询历史接口
export function gethistorymsgV2(params) {
  return request({
    url: 'api/aiv2/gethistorymsgV2',
    params: params,
    method: 'get'
  })
}
// 清空历史记录接口
export function cleanusermsg(params) {
  return request({
    url: 'api/aiv2/cleanusermsg',
    params: params,
    method: 'get'
  })
}
// 英语老师-英语问答
export function aiEnglish(params) {
  return request({
    url: 'api/ai/aiEnglish',
    method: 'POST',
    // contentType: 'multipart/form-data',
    // contentType: 'application/x-www-from-urlencoded',
    params: params
  })
}
// 英语老师-获取token
export function getAiEnToken(params) {
  return request({
    url: 'api/aiv2/getAiEnToken',
    method: 'POST',
    params: params
  })
}
// 英语老师-翻译回复接口
export function chatAiEnglish(params) {
  return request({
    url: 'api/aiv2/chatAiEnglish',
    method: 'POST',
    params: params
  })
}
// AI英语语音合成接口
export function speechSynthesizer(params) {
  return request({
    url: 'api/aiv2/speechSynthesizer',
    method: 'POST',
    data: qs.stringify(params)
  })
}

// 历史记录
export function gethistorymsg_wav(params) {
  return request({
    url: 'api/aiv2/gethistorymsg_wav',
    method: 'POST',
    data: qs.stringify(params)
  })
}

// 规范点赞、踩的事件
export function setAilog(params) {
  return request({
    url: 'api/aiv2/setAilog',
    method: 'POST',
    data: qs.stringify(params)
  })
}

// 获取回话列表
export function getConversations(params) {
  return request({
    url: 'api/aiv3/conversations',
    method: 'GET',
    params: params
  })
}

// 获取会话下的聊天记录
export function getCurrentMessages(params) {
  return request({
    url: 'api/aiv3/messages',
    method: 'GET',
    params: params
  })
}
